import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import router from './router';
import store from './store';
import styles from './assets/scss/main.scss';
import i18n from './i18n';

import App from './App';

Vue.use(vClickOutside);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  styles,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
